@mixin media-breakpoint-up($breakpoint) {
  @media screen and (min-width: map-get($grid-breakpoints-up, $breakpoint)) {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @media screen and (max-width: map-get($grid-breakpoints-down, $breakpoint)) {
    @content;
  }
}

@mixin textOverflowClamp($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin elegantScrollbar($hiddenInSmallScreen: false) {
  scrollbar-width: thin;
  scrollbar-color: #bbb #f4f4f4;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  @if $hiddenInSmallScreen {
    @include media-breakpoint-down(md) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

// For login and forgot password usage
%loginFormInput {
  padding: px_rem(14) px_rem(24);
  border-radius: 100px;
  font-size: px_rem(16);
}

@mixin loginComponent {
  margin: auto;
  padding-inline: 2rem;
  min-width: 100%;
  @include media-breakpoint-up(lg) {
    min-width: calc(px_rem(480) + 2rem);
  }

  form {
    :global(.ant-input) {
      @extend %loginFormInput;
    }
  }
  &__title {
    font-size: px_rem(40);
    font-weight: bold;
  }
  &__tab {
    margin-block: px_rem(70) px_rem(48);
    font-size: px_rem(18);
    position: relative;
    width: max-content;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: #cecece;
    }
    button {
      font-size: inherit;
      padding-inline: px_rem(30);
      line-height: calc((20 + 18) / 18);
    }
  }
  .form {
    &__verificationInput {
      display: flex;
      gap: px_rem(30);
      > * {
        &:first-child {
          flex: 2 1 60%;
          @extend %loginFormInput;
        }
      }
      > button {
        flex: 1 1 40%;
        @extend .btn__primary;
        height: auto;
        max-width: unset;
        padding-inline: px_rem(16);
        min-width: max-content;
      }
    }
    &__button {
      @extend .btn__primary;
      height: auto;
      max-width: unset;
      text-align: center;
      &--link {
        display: block;
        margin-left: auto;
        width: max-content;
      }
      & + .form__button {
        background: transparent;
        color: var(--primaryTextColor);
        padding-block: px_rem(20);
        line-height: 1;
      }
      &Wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
