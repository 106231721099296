.btn__primary {
  height: 42px;
  line-height: 42px;
  max-width: 120px;
  padding-inline: 1.7rem;
  width: 100%;
  border-radius: 50px;
  background-color: #1169f7;
  color: #fafafa;
  font-size: px_rem(16);
  font-weight: bold;
  transition: .2s all ease-in-out;
  cursor: pointer;

  &--white {
    background-color: white;
    color: #1169f7;
    border: 1px solid #1169f7;
  }

  &--yellowText {
    background-color: white;
    color: #fac84c;
    border: 1px solid #fac84c;
  }

  &:hover {
    opacity: .75;
  }

  @include media-breakpoint-down(lg) {
    height: 30px;
    line-height: 30px;
    padding-inline: px_rem(19, 12);
    max-width: unset;
    width: unset;
    font-weight: normal;
  }
}