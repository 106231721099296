// .content-outer {
//   background-color: #ffffff;
//   .content-wrapper {
//     display: flex;
//     align-items: center;
//     position: relative;
//     background-color: #ffffff;
//     max-width: 1920px;
//     margin: 0 auto;
//     .login-banner {
//       max-height: 100vh;
//       height: 100%;
//       overflow: hidden;
//       .imageWrapper {
//         max-width: 972px;
//         max-height: 1080px;
//         width: 100%;
//         height: auto;

//         img {
//           object-fit: contain;
//         }
//       }
//       .logo {
//         position: absolute;
//         width: 192px;
//         height: 60px;
//         top: 78px;
//         left: 153px;
//       }
//     }
//   }
// }
@import '@/scss/abstracts/variables.scss';
@import '@/scss/abstracts/functions.scss';
@import '@/scss/abstracts/mixins.scss';
@import '@/scss/components/buttons.scss';
.containerWrapper {
  background-color: #ffffff;
  &__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    > img:not(.containerWrapper__image--logo) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &--logo {
      max-width: 192px;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      margin: px_rem(78);
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
  > div {
    display: grid;
    grid-template-rows: max(100vh, 600px);
    @include media-breakpoint-up(lg) {
      grid-template-columns: 50% 50%;
    }
    > div {
      &:first-child {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }
}
